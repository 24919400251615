import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Ovveride for verfication code styles */
  :root {
    --ReactInputVerificationCode-itemWidth: 3rem !important;
    --ReactInputVerificationCode-itemHeight: 3rem !important;
  }

  @font-face {
    font-family: "BasierSquare-Bold";
    src: url("/fonts/BasierSquare-Bold.otf") format("opentype");
  }

  @font-face {
    font-family: "BasierSquare-SemiBold";
    src: url("/fonts/BasierSquare-SemiBold.otf");
  }

  @font-face {
    font-family: "BasierSquare-Regular";
    src: url("/fonts/BasierSquare-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: "OpenSans-Regular";
    src: url("/fonts/OpenSans-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "OpenSans-SemiBold";
    src: url("/fonts/OpenSans-SemiBold.ttf") format("truetype");
  }


  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  body, html {
    height: auto !important;
  }

  body {
    margin: 0;
    padding: 0;
  }

  header {
    width: 100vw;
  }

  h1, h2, h3 ,h4 {
    font-family: BasierSquare-Bold;
    font-weight: normal !important;
  }


  span, p, input, a, li, button {
    font-family: BasierSquare-Regular;
    font-weight: normal !important;
  }


  p {
    line-height: 150%;
  }


  button {
    height: auto !important;
  }

  #__lpform_name_icon {
    display: none;
  }

  *::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

`;

export default GlobalStyle;
