import { extendTheme } from "@chakra-ui/react";
import Button from "./components/Button";

const theme = extendTheme({
  fonts: {
    heading: "BasierSquare-Bold",
    body: "BasierSquare-Regular",
  },
  colors: {
    green: {
      500: "#01A386",
    },
    brand: {
      green: "#01A386",
    },
  },
  components: {
    Button,
    Input: {
      baseStyle: {
        backgroundColor: "white",
        fontSize: "32px",
      },
    },
  },
});

export default theme;
