import App from "next/app";
import ReactGA from "react-ga";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import Head from "next/head";
import { IntercomProvider } from "react-use-intercom";
import GlobalStyle from "../styled/GlobalStyles";
import useClient from "../util/client";
import theme from "../theme/index";

import "../css/main.css";

const INTERCOM_APP_ID = "gn8mem2k";

export default class MyApp extends App {
  render(): JSX.Element {
    const client = useClient();
    ReactGA.initialize("UA-149436748-2");
    const { Component, pageProps, router } = this.props;

    return (
      <>
        <Head>
          <title>TRIM-IT Mobile Barbershops</title>
          <meta
            name="description"
            content="The UK’s largest mobile barbershop, book a barber to your house, gym or office!"
          />
          <meta
            property="og:image"
            content="https://trimit.app/images/open-graph-image.jpg"
          />
          <meta property="og:title" content="TRIM-IT Mobile Barbershops" />
          <meta
            property="og:description"
            content="The UK’s largest mobile barbershop, book a barber to your house, gym or office!"
          />
        </Head>

        {process.env.NODE_ENV === "production" &&
          !router.route.includes("/referral/") &&
          !router.route.includes("/refer/") &&
          !router.route.includes("/events") &&
          !router.route.includes("/launch") &&
          !router.route.includes("/powered-by") &&
          !router.route.includes("/powered-by/launch") &&
          !router.route.includes("/powered-by/editor") &&
          !router.route.includes("/terms-of-service") &&
          !router.route.includes("/barber-connect") && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
              var _iub = _iub || [];
              _iub.csConfiguration = {"countryDetection":true,"cookiePolicyInOtherWindow":true,"consentOnContinuedBrowsing":false,"lang":"en","siteId":1846205,"perPurposeConsent":true,"cookiePolicyId":59381459, "banner":{ "rejectButtonDisplay":true,"rejectButtonColor":"#262626","rejectButtonCaptionColor":"white","position":"float-bottom-center","textColor":"black","backgroundColor":"white","acceptButtonDisplay":true,"acceptButtonColor":"#00b5a3","acceptButtonCaptionColor":"white" }};`,
                }}
              />
              <script
                type="text/javascript"
                src="//cdn.iubenda.com/cs/iubenda_cs.js"
                charSet="UTF-8"
                async
              />
            </>
          )}

        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GMAP_API_KEY}&libraries=places`}
        />
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GMAP_API_KEY}&libraries=places`}
        />

        {process.env.NODE_ENV === "production" && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="sG8DQ94GQg7Z7x2UQSRu7OnmifwnOmkJ";;analytics.SNIPPET_VERSION="4.15.3";
              analytics.load("sG8DQ94GQg7Z7x2UQSRu7OnmifwnOmkJ");
              analytics.page();
              }}();`,
            }}
          />
        )}

        {/* Global Site Code Pixel - Facebook Pixel */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
              `,
          }}
        />

        <ApolloProvider client={client}>
          <GlobalStyle />
          <ChakraProvider theme={theme}>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <Component {...pageProps} key={router.route} />
            </IntercomProvider>
          </ChakraProvider>
        </ApolloProvider>
      </>
    );
  }
}
