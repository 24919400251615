import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const useClient = (): ApolloClient<NormalizedCacheObject> => {
  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token =
      typeof window !== "undefined" ? localStorage?.getItem("token") : null;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token,
        "x-tr-id": process.env.NEXT_PUBLIC_ACCESS_TOKEN,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_DB_URL,
  });

  const client = new ApolloClient({
    name: "react-website-web",
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return client;
};

export default useClient;
