const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "normal",
    borderRadius: "50px",
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "14x",
      padding: "12px 8px",
    },
    md: {
      fontSize: "14px",
      padding: "20px 10px",
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      border: "1px solid white",
      bg: "transparent",
      color: "white",
    },
    secondary: {
      bg: "white",
      color: "black",
      border: "2px solid",
      borderColor: "gray.200",
    },
    outline: {
      borderColor: "black",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "sm",
    variant: "primary",
  },
};

export default Button;
